<template>
  <div>
    <div class="book">
      <div
        v-for="(pages, index) in productsInfoPrint"
        :key="pages + index"
        class="page">
        <div
          style="display: flex; flex-wrap: wrap;">
          <div
            v-for="(page, index) in pages"
            :key="page + index">
            <div
              style="width: 155px;"
              class="mt-4">
              <qrcode-vue
                :value="page.code"
                :size="120"
                level="H"
                render-as="svg"
                style="text-align: center;"
              />
              <div style="font-size: 10px; text-align: center; line-height: 20px;">
                <span v-if="showInfo.nameProduct"><span class="font-weight-bold">{{ page.name }}</span></span>
                <span
                  v-if="showInfo.codeProduct"
                  style="display: block;">{{ $t('common.code') }}: <span class="font-weight-bold">{{ page.code }}</span></span>
                <span v-if="showInfo.pointProduct">{{ $t('common.point') }}: <span class="font-weight-bold">{{ page.points }}</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn-print"
      style="position: fixed; bottom: 0;">
      <v-btn
        class="btn"
        color="warning"
        dark
        @click="onPrintPage"
      >
        <v-icon class="mr-2">mdi-printer</v-icon>
        <span style="text-transform: none;">{{
          $t('common.print')
        }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    QrcodeVue
  },
  data: () => ({
    productsInfoPrint: [],
    showInfo: {}
  }),
  computed: {
    ...mapGetters(['GET_PRODUCT_PRINT_DATA'])
  },
  watch: {
    GET_PRODUCT_PRINT_DATA: function () {
      this.productsInfoPrint = []
      let productInfo = this.GET_PRODUCT_PRINT_DATA
      let count = 0
      for (let i = 0, size = productInfo.length; i < size; i++) {
        if (Array.isArray(this.productsInfoPrint[count])) {
          if (this.productsInfoPrint[count].length < 20) {
            this.productsInfoPrint[count].push(productInfo[i])
          } else {
            count += 1
            this.productsInfoPrint[count] = []
            this.productsInfoPrint[count].push(productInfo[i])
          }
        } else {
          this.productsInfoPrint[count] = []
          this.productsInfoPrint[count].push(productInfo[i])
        }
      }
    }
  },
  created () {
    // let productInfo = JSON.parse(this.$route.query.productInfo)
    // let count = 0
    // for (let i = 0, size = productInfo.length; i < size; i++) {
    //   if (Array.isArray(this.productsInfoPrint[count])) {
    //     if (this.productsInfoPrint[count].length < 20) {
    //       this.productsInfoPrint[count].push(productInfo[i])
    //     } else {
    //       count += 1
    //       this.productsInfoPrint[count] = []
    //       this.productsInfoPrint[count].push(productInfo[i])
    //     }
    //   } else {
    //     this.productsInfoPrint[count] = []
    //     this.productsInfoPrint[count].push(productInfo[i])
    //   }
    // }
    let showInfo = JSON.parse(this.$route.query.showInfo)
    this.showInfo = showInfo ? showInfo.showInfo : {}
    let productIds = this.$route.query.productIds
    let filter = {
      params: {
        productIds: productIds
      }
    }
    this.GET_PRODUCT_PRINT(filter)
  },
  methods: {
    /**
     * Print page
     */
    onPrintPage: function () {
      window.print()
    },
    ...mapActions(['GET_PRODUCT_PRINT'])
  }
}
</script>

<style lang="scss">
body.print {
    overflow: initial;
}
 body.print {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        font: 12pt;
    }
    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .page {
        width: 210mm;
        min-height: 297mm;
        padding: 2mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    .subpage {
        height: 257mm;
    }

    @page {
        size: A4;
        margin: 0;
    }
    @media print {
        html {
            width: 100%;
            height: 297mm;
        }
        body.print {
            width: 100%;
            height: 297mm;
        }
        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
        .btn-print {
            display: none;
        }
    }
.theme--dark.v-sheet {
    padding: 5px;
}
</style>
